import React from "react";
import Layout from "../components/layout";

import HEAD from "../components/head";

const displayName = "Red Apple Solutions";
const website = "redapple.solutions";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Layout>
        <HEAD
          title="Privacy Policy"
          keywords={[
            `red apple solutions`,
            `education insurance`,
            `school employee insurance`,
            `insurance for schools`,
            `school insurance`,
            `college insurance`,
            `university insurance`,
            `school employee benefits`,
            `privacy policy`
          ]}
        />
        <div style={{ padding: "35px" }}>
          <h1>Privacy Policy</h1>
          <p>Effective Date: June 1, 2018</p>
          <p>
            {displayName} highly values and respects your privacy. We have
            written this privacy policy to help you understand the information
            we collect throughout our Website; how we utilize and share this
            information; the choices you have regarding the use; and other
            important information about the collection and use of information
            obtained through our Site. Although some of the concepts may be
            technical, we have tried to layout the concepts in a straightforward
            and transparent way. Please take some time to familiarize yourself
            with the Policy before using our Services. If you have any question
            or questions about our {displayName}
            privacy policy or the ways in which {displayName} utilizes the
            information we collect, please do not hesitate to contact us through
            the contact form located on our home page.
          </p>

          <p>
            By accessing or using our Services, you are agreeing to this Policy
            and to our Terms of Use. If you do not agree to this Policy or Terms
            of Use, please do not access or otherwise use our Services or any
            information contained herein.
          </p>

          <h2>Scope of Our Privacy Policy</h2>
          <p>
            This privacy policy applies to information collected through our
            website ({website}) and any other online products and services that
            display or link to this privacy policy (collectively, “Website” or
            “Site”).
          </p>
          <p>
            This policy does not apply to website, mobile apps or other online
            products and services that have their own privacy policy or
            otherwise do no display or link to this policy. In addition, this
            policy does not apply to information collected offline.
          </p>

          <h2>Protection of Children’s Privacy</h2>
          <p>
            IF YOU ARE UNDER 18 YEARS OF AGE, THEN PLEASE DO NOT ACCESS THE
            SERVICES AT ANY TIME OR IN ANY MANNER.
          </p>

          <p>
            Our Websites are not intended for children under the age of
            18-years-of-age and are not permitted to use our Website. Protecting
            the privacy of young children is extremely important. We believe it
            is important to provide added protection for children online. We
            encourage parents and guardians to spend time online with their
            children to observe, participate in and/or monitor and guide their
            online activity. For those reasons, {displayName} does not knowingly
            collect or maintain Personal Information from persons under 18
            years-of-age. If we learn and/or informed of persons under
            18-years-of-age, we will take the appropriate steps to delete this
            information. If a parent or guardian believes that {displayName} has
            in its database the Personal Information of a child under the age of
            18-years-of-age, please contact us immediately using the contact in
            the second paragraph, and we will take the appropriate steps to
            delete this information.
          </p>

          <h2>Collection of Personal Information</h2>
          <p>
            Keeping your information private and secure is our highest priority.
            These paragraphs explain what we collect and why we collect it:
          </p>

          <p>
            We collect personally identifiable information (“personal
            information”) that you submit or otherwise choose to share with us,
            as well as personal information received from our partners and
            service providers. Personal information means information that
            identifies you or could reasonably be used to identify you, such as
            your name, business name, postal address, email address, and similar
            information. You can generally visit our Website without revealing
            any Personal Information about yourself. However, to obtain your
            services, we will ask you to provide certain Personal Information.
            If you choose not to provide this information, you may be unable to
            obtain our services.
          </p>
          <p>
            In addition, we collect certain non-identifiable information
            automatically when you engage with our Site regarding your computer
            or device or your interaction with our Site. This information may
            include the data and time your browser requested our Site, a device
            identifier, or Internet Protocol Address, the pages of our Site or
            features used or other non-identified information about your use of
            our Site or advertisements (“ads”).
          </p>

          <h2>Personal Information You Submit</h2>
          <p>
            We collect Personal Information from you through the Website when
            you choose to provide it to us. Additionally, when you submit a
            question or otherwise choose to contact us, you provide us with any
            personal information you choose to include in your communication.
          </p>

          <h2>Personal Information From Third Parties</h2>
          <p>
            We may also receive Personal Information about you from third
            parties. For example, we may obtain information about you from
            commercially available sources such as data aggregators and public
            databases. This information may include your name, demographic
            information, business name, business contact information, business
            location, and other similar information.
          </p>

          <h2>Non-Identifiable Information</h2>

          <p>
            As you navigate through and interact with our Website, we may use
            automated data collection technologies to collect certain
            information about your equipment, browsing action, and patterns
            specifically:
          </p>
          <ul>
            <li>
              Details of your visits to our Website, specifically traffic data,
              location data (through IP addresses), logs, referring/exit pages,
              date and time of your visit to our Site, error information, and
              other communication data and the resources for the products you
              access and use on the Website; and
            </li>
            <li>
              Information about your computer and internet connection,
              specifically your IP address, operating system, language, and
              browser type.
            </li>
          </ul>
          <p>
            The information we collect automatically may include Personal Data
            or we may maintain it or associate it with Personal Data we collect
            in other ways. It helps us to improve our Website and to deliver a
            better more personalized service by enabling us to:
          </p>
          <ul>
            <li>Estimate our audience size and usage patterns;</li>
            <li>
              Store information about your preferences, allowing us to customize
              our Site to your individual interests;
            </li>
            <li>Prevent or deter theft of our intellectual property;</li>
            <li>Speed up your access; and</li>
            <li>Recognize you when you use and return to our Website</li>
          </ul>

          <p>
            Details about the automated data collection technologies and the
            specific cookies that we use can be below in Cookies and Similar
            Technologies section.
          </p>

          <h2>Use of Information</h2>
          <p>
            We may use the information we collect from and about you (or your
            device) for any of the following purposes:
          </p>
          <ul>
            <li>
              To provide you with pricing information regarding our services;
            </li>
            <li>
              To personalize your experience and to allow us to deliver the type
              of content and service offerings in which you are most interested
              including customized content, targeted offers and advertising
              through our Site, other sites, and via email;
            </li>
            <li>To improve our Website and services;</li>
            <li>
              To allow us to better service you in responding to your customer
              service request;
            </li>
            <li>
              To send emails or other communications regarding our services;
            </li>
            <li>
              To respond to governmental requests and as required by applicable
              law, court order, and government regulations;
            </li>
            <li>
              As otherwise described to you at the point of data collection and
              in this privacy policy.
            </li>
          </ul>

          <h2>Disclosure Information</h2>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            Personal Information unless we provide you with advance notice or as
            otherwise described in this policy. This does not include our
            financial partners, affiliates, website hosting partners, and other
            parties who assist us in operating our Site, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our Site policies, or protect ours or other’s rights,
            property, or safety.
          </p>
          <p>
            In addition, during the normal course of our business, {displayName}
            may sell or purchase assets. If another entity acquires us or any of
            our assets, information we collect from and about you (and your
            device) may be transferred to such entity. In addition, if any
            bankruptcy or reorganization proceeding is brought by or against us,
            such information may be considered an asset of ours and may be sold
            or transferred to third parties. Should such a sale or transfer
            occur, we will use reasonable effort to try to require that the
            transferee use the information we collect from you and about you
            (and your device) in a manner that is consistent with this privacy
            policy.
          </p>

          <p>
            However, non-identified information may be provided to other parties
            for marketing, advertising, or other use.
          </p>

          <h2>
            This section describes the different types of cookies and similar
            technologies that may be used on our {displayName} Website.
          </h2>
          <p>Use of Cookies and Similar Technologies</p>

          <p>
            We may change this section information at any time. It is our policy
            to post any changes we make to our policy regarding cookies in this
            section. The date that this Privacy Policy was last revised is
            identified at the top of this page.
          </p>

          <p>
            If you have further questions, please contact us through our contact
            form located on our home page.
          </p>

          <h3>What Are Cookies and Similar Technologies Used on Our Site</h3>
          <p>
            <b>Cookies (or browser cookies).</b> A cookie is a small file placed
            on the hard drive of your computer. Except for essential cookies and
            preference cookies, we only place cookies on your hard drive with
            your consent. Our use of cookies is governed by the above privacy
            policy. You can set your browser to refuse all or some browser
            cookies, or to alert you when cookies are being sent. However, if
            you do no consent to our use of cookies or select this setting you
            may be unable to access certain parts of our Website. You can find
            more information about cookies at http://www.allaboutcookies.org and
            http://your on http://youronlinechoices.eu.
          </p>

          <p>
            <b>Pixel tags (or web beacons).</b> Pixel tags are small blocks of
            code installed on our Site which can be used to track activity on
            our
            {displayName} Site, allows our online partners to serve cookies when
            you visit our Site, receive relevant device and usage data, and
            perform other activities relating to the monitoring and analysis of
            the use and performance of our Site.
          </p>

          <p>
            <b>Server logs.</b> Server logs refers to the information
            automatically recorded by a server when your browser requests a
            website or other online service, such as Internet Protocol (IP)
            address, browser type, browser language, date and time of your
            request, and one or more cookies that may uniquely identify your
            browser.
          </p>
          <p>
            <b>Statistical identifiers.</b> Statistical identifiers refer to
            identifiers created using your device information and certain server
            log information. This information, collectively, makes your browser
            or device sufficiently distinct for a server or similar system to
            reasonably determine that it is encountering the same browser or
            device over time. Statistical identifiers enable us to uniquely
            identify your browser or device for the same purpose as cookies.
          </p>

          <h3>Use of Cookies and Similar Technologies</h3>
          <p>
            These cookies and similar technologies are used to collect
            information about your computer or device and your interaction with
            our Site and ads. This information may include the online services
            requested (for example, {website}); your device’s IP address; unique
            device and advertising identifiers; location data derived from your
            device; browser type and language; device operating system; device
            hardware and other characteristics; device event information (for
            example, crash data); information regarding your engagement with our
            online services (for example, features used, number of clicks, time
            spent on a particular page); demographic information; data contained
            in first-party cookies; and other non-identifiable device and usage
            data.
          </p>

          <h3>Usage of Cookies and Similar Technologies By Categories</h3>

          <p>
            Cookies and similar technologies used on our Site (including the
            information obtained through their use) fall into three categories:
          </p>

          <h4>1. ANALYTICS</h4>
          <p>
            Our Website utilizes first- and third-party cookies and similar
            technologies for analytics, product development and market research
            purposes, including analyzing how users arrive and engage with our
            Site or ads; tracking and measuring the performance and
            effectiveness of our Site and ads; optimizing the location of ads,
            features and other content on our Site; statistical reporting
            connection with website activity; analyzing user preferences and
            behaviors and market characteristics and trends in order to develop
            and improve our services and Sits; segment audiences to test
            features of Site and ads; and conduct research about our consumers
            and services. Our {displayName} Site utilizes Google Analytics that
            helps determine the above information. The tracking information
            allows us to better understand the {displayName} traffic, and the
            content reached. If you would like to opt out of tracking, you can
            do so through Google’s settings or use a browser plugin to opt out
            of all Google Analytics tracking software.
          </p>
          <h4>2. ESSENTIAL</h4>
          <p>
            Our Website utilizes first- and third-party cookies and similar
            technologies that are necessary in order to operate our business and
            maintain our Site, including preventing fraud and securing our Site;
            enabling users to move around our Website and use its features; and
            complying with applicable laws and industry standards. These cookies
            and similar technologies don’t gather information about you that
            could be used for marketing or tracking your browser activity. This
            category of cookies generally cannot be disabled.
          </p>
          <h4>3. PERFORMANCE</h4>
          <p>
            Our website utilizes first- and third-party cookies and similar
            technologies to provide enhanced features and remember users
            preferences, including identifying return user when visiting our
            Site; keeping track of user preferences, interests, and past items
            viewed or utilized.
          </p>

          <h3>Your Cookie Choices</h3>
          <p>
            Most browsers are set up to accept cookies by default, however,
            browsers typically allow individuals to remove or block browser
            cookies if they choose. For more information on blocking and
            removing browser cookies, please review your browser’s Settings or
            Preferences tab. Please note that removing or blocking browser
            cookies does not necessarily affect third party “Flash” cookies that
            may be utilized on our site. T
          </p>

          <p>
            In addition to removing and blocking browser cookies generally, the
            following opt-out mechanisms may be available where our Site
            utilizes the third-party cookies listed:
          </p>

          <p>
            <b>Google Analytics.</b> Our Site may use Google Analytics, a web
            analytics service provided by Google. Google Analytics utilizes
            cookies and similar technologies to collect and analyze
            non-identified information about the performance and use of our
            Website. More information on Google Analytics can be found here. If
            you would like to opt-out of having your information collected and
            used as described, please use the Google Analytics opt-out available
            here.
          </p>

          <p>
            Please note, disabling, removing or blocking cookies may turn off
            some of the features of our Site that make your experience more
            efficient, and some Site features may not function properly.
            Further, if you opt-out of having your information collected and
            used by us and our online partners for interest based advertising
            purposes, you will continue to receive ads, but such ads will not be
            tailored to your specific interests based on viewing data collected
            from your device over time and across unaffiliated sites.
          </p>

          <h3>Do Not Track (DNT) Headers</h3>
          <p>
            DNT is a concept that has been promoted by regulatory agencies for
            the internet industry to develop and implement a mechanism for
            allowing internet users to control the tracking of their online
            activities across websites by using browser settings. The World Wide
            Web Consortium has been working with industry groups, internet
            browsers, technology companies, and regulators to develop a DNT
            technology standard. While some progress has been made, no standard
            has been adopted to date. As a result, we do not generally respond
            to “do not track” signals.
          </p>

          <h2>Security</h2>
          <p>
            Information transmitted over the Internet is not completely secure,
            but We do our best to protect your personal Data. You can help
            protect your Personal Data and other information by keeping your
            passwords confidendential.
          </p>
          <p>
            We have implemented measures designed to secure your Personal Data
            from accidental loss and from unauthorized access, use, alteration,
            and disclosure.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we do our best to protect your
            Personal Data, we cannot guarantee the security of your Personal
            Data transmitted to our Website. Any transmission of Personal Data
            is at your own risk. We are not responsible for circumvention of any
            privacy setting or security measures contained on the Website.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions or comments about this privacy policy or
            the ways in which we use information covered by this policy, please
            do not hesitate to contact us through our contact form located on
            our home page.
          </p>

          <h2>Policy Change</h2>
          <p>
            We reserve the right to amend this privacy policy without prior
            notice to reflect technological advancements, legal and regulatory
            changes, and good business practices. If we change our privacy
            practices, a new privacy policy will reflect those changes and the
            effective date of the revised privacy policy will be set forth at
            the top of this privacy policy. If we make any material changes to
            this privacy policy relating to our use or disclosure of Personal
            Information, we will provide you with reasonable notice of such
            changes (for example, by including a banner on our Site or sending
            you an email).
          </p>
        </div>
      </Layout>
    );
  }
}

export default PrivacyPolicy;
